import { Grid } from "@mui/material";
import { LegacyStack, Text, Thumbnail } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { getPurchasableImage } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import React, { useEffect, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { MAX_IMAGES_TO_DISPLAY } from "@vendor-app/app/AdminRoute/AdminCreateSubscriptionRouteNew/libs";

export interface IProductsImages {
  productIds: number[];
}

export const ProductsImages = ({ productIds }: IProductsImages): JSX.Element => {
  const [images, setImages] = useState<IPurchasable[]>([]);
  const [imagesHidden, setImagesHidden] = useState<number>(0);

  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  const limitArrLength = (arr: any[]) => {
    if (arr.length > MAX_IMAGES_TO_DISPLAY) {
      setImagesHidden(arr.length - 1 - MAX_IMAGES_TO_DISPLAY);
      arr.length = MAX_IMAGES_TO_DISPLAY;
      return arr;
    }
    return arr;
  };

  const getEligibleItemWithImage = (id: number, item: IPurchasable) => {
    return item.shopifyId?.includes(id.toString()) && getPurchasableImage(item);
  };

  const getProductsImages = (productIds: number[]) => {
    if (productIds.length) {
      const purchasablesArr: IPurchasable[] = [];

      productIds.map(id => {
        const items = purchasables.find(item => getEligibleItemWithImage(id, item));
        items ? purchasablesArr.push(items) : null;
      });
      setImages(limitArrLength(purchasablesArr));
    }
    return [];
  };

  useEffect(() => {
    getProductsImages(productIds);
  }, [productIds]);

  return (
    <React.Fragment>
      {images.length ? (
        <Box mt={0.8} data-testid="product-images-container">
          <LegacyStack alignment="center" spacing="tight">
            <Grid
              container
              spacing={"7px"}
              sx={{
                alignItems: "center",
                width: "max-content",
              }}
            >
              {images.map(purchasable => {
                return (
                  <Grid item key={purchasable.purchasableName}>
                    <Thumbnail
                      size="small"
                      source={getPurchasableImage(purchasable)!}
                      alt={purchasable.purchasableName}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {imagesHidden ? (
              <Text variant="bodyMd" as="p" color="subdued">
                {`+${imagesHidden} more`}
              </Text>
            ) : null}
          </LegacyStack>
        </Box>
      ) : null}
    </React.Fragment>
  );
};
